.ita-news {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 100px;
}

.ita-news-text {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ita-news-text h3 {
  font-weight: bolder !important;
  padding: 0px 20px;
}

.ita-news-text p {
  font-size: 15px;

  text-align: justify;

  padding: 20px 50px;
}

.ita-news-text span {
  background-color: #1e2e59;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
}

.ita-news-first {
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ita-news-sec1 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news1-f {
  width: 60%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.news2-f {
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.news1-f img {
  width: 70%;
  height: auto;
  margin: 50px 0px;
}

.news2-f img {
  width: 60%;
  height: auto;
  margin: 50px 0px 20px 0px;
}
/*  */

.ita-news-second {
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.ita-news-sec2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ita-news3-s {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-left: 30px;
}

.ita-news3-s img {
  width: 60%;
  height: auto;
  margin: 50px 0px 20px 0px;
}

.ita-news4-s {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ita-news4-s img {
  width: 70%;
  height: auto;
  margin: 50px 0px 20px 0px;
}

/*  */

.news-date {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-top: 50px;
  padding-left: 80px;
}

/* carousel */

.css-ohwg9z {
  width: 90%;
}

.carousel {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel img {
  width: 350px;
  height: 250px;

  border-radius: 50px;

  object-fit: contain;

  padding: 20px;
}

.carousel p {
  width: 100%;
  height: 50px;
  text-align: center;
  font-weight: bold;
}

.carousel-btn {
  background-color: #1e2e59;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
}

/* mobile design */
@media screen and (max-width: 480px) {
  .news-date {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
    padding-left: 80px;
    padding-bottom: 50px;
  }
  .ita-news {
    width: 100%;
    height: auto;
    padding-top: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 100px;
  }
}
