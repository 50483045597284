.ita-wecont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: auto;
}

.ita-wecont h2 {
  color: #54c86e;
  font-size: 1.6rem;

  padding: 20px 0px;
  font-weight: lighter;
  text-align: center;
}

.ita-wesec1 {
  width: 80%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 50px;
}

.first-sect {
  width: 100% !important;
  height: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50%;
  height: auto;
}

.first-sect div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;
}

.ita-wesec1 img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.ita-we-info-text {
  width: 80% !important;
  height: 70% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  text-align: justify;
}

.ita-wesec2 {
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 50px;
}

.ita-wesec2 div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 50%;
  height: auto;

  margin-bottom: 50px;
}

.ita-wesec3 {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 100px;
}

.ita-wesec3 div {
  width: 100% !important;
  height: auto !important;
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 50%;
  height: auto;
}

.ita-wesec3 img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.ita-wesec3 img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.final-sec {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.final-sec2 {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 100px;
}

.ita-historia {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 50px;
}

/*  */
footer {
  border: black solid 1px;
  background: #0a0a2c;
  color: white;
  padding: 1.1rem 0;
}
footer h3 {
  font-size: 0.9rem;
  font-weight: lighter;
}

.ita-wecont p {
  line-height: 1.5rem;
}

/* mobile design */
@media screen and (max-width: 480px) {
  .ita-wecont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 100%;
    height: auto;
    padding: 0px 20px;

    margin-top: 100px;
  }

  .final-sec {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .final-sec2 {
    width: 90%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ita-wesec3 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  .ita-wesec3 div {
    width: 100% !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
  }

  .fin-logo {
    margin-top: 50px;
  }

  .ita-wesec2 div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: auto;
    margin-bottom: 100px;
  }

  .ita-wesec1 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px;
  }

  .ita-wesec1 div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: auto;

    margin-bottom: 20px;
  }
}