.ita-contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;
  overflow-x: hidden;
}

.ita-contact-container h2 {
  color: #54c86e;
  font-size: 1.6rem;

  padding: 20px 0px;
  font-weight: lighter;
}

.ita-contact-sec1 {
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 0px;
  margin-bottom: 100px;
}

.ita-contact-info {
  width: 30%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.ita-contact-info div {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 30px 0px 0px 100px;
}

.ita-contact-form {
  width: 70%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 50px;

  margin-top: 100px;
}

/*  */
.contact-us-form {
  width: 80%;

  display: grid;
  grid-template-columns: 50% 50%;
}

.contact-us-form input {
  width: 100%;
  padding: 0.5rem;

  border-style: none;
  border-radius: 10px;

  margin-top: 5px;

  outline: none;

  text-align: center;
  font-size: 15px;

  caret-color: #919191;
  background-color: #1e2e59;

  color: #ffffff;
}

::placeholder {
  color: #d9d9d9;
}

.contact-us-form div {
  margin: 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
}

.contact-us-form label {
  font-size: 15px;

  color: #919191;

  position: absolute;

  top: -20px;
  left: 0;
}

.contact-us-form-name-inp {
  grid-column: 1;
}
.contact-us-form-lastname-inp {
  grid-column: 2;
}
.contact-us-form-email-inp {
  grid-column: 1;
}
.contact-us-form-business-inp {
  grid-column: 2;
}

.contact-us-form-message-txta {
  grid-column: 1 / span 2;

  margin-top: 20px;
}

.contact-us-form textarea {
  width: 100%;
  height: 100px;

  border-style: none;
  border-radius: 20px;
  outline: none;

  padding: 20px 30px;

  resize: none;

  margin-top: 5px;

  background-color: #1e2e59;
}

.contact-us-form-send-btn {
  grid-column: 1 / span 2;

  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-form-send-btn span {
  background-color: #1e2e59;

  font-family: "Spinnaker", sans-serif;
  font-size: 20px;
  color: #ffffff;

  padding: 5px 50px;

  border-radius: 10px;
}

/*  */
.map-container {
  width: 100%;
  height: auto;

  margin-bottom: 50px;
}

.map-container iframe {
  border-style: none;
  width: 100%;
}

.ita-cont-text {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 0px 0px 100px;
  text-align: left;
}

.ita-cont-text p {
  font-weight: lighter;
  font-size: 22px;
}

form {
  width: 100% !important;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column: 1 / span 2;
}

.btn-enviar {
  grid-column: 1 / span 2;
  width: 100px;
  height: 35px;

  background-color: #54c86e;
  border-style: none;
  border-radius: 10px;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}

/*  */
footer {
  border: black solid 1px;
  background: #0a0a2c;
  color: white;
  padding: 1.1rem 0;
}
footer h3 {
  font-size: 0.9rem;
  font-weight: lighter;
}

/*  */
.whatsapp-icon {
  width: 150px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #1e2e59;

  border-radius: 30px;

  cursor: pointer;

  color: #ffffff;

  position: fixed;

  top: 85%;
  right: 5px;
  z-index: 10;
}

.whatsapp-icon img {
  width: 50px;
  height: 50px;

  object-fit: contain;

  margin-right: 10px;
}

/* mobile design */
@media screen and (max-width: 480px) {
  .ita-contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: auto;
    overflow-x: hidden;

    padding: 0px 20px;
    padding-top: 100px;
  }

  .ita-contact-sec1 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .ita-contact-info {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .ita-contact-info div {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;

    text-align: justify;
  }

  .ita-contact-form {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    margin-top: 50px;
  }

  .contact-us-form {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .contact-us-form div {
    margin: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
