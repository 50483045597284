.services-sect-container {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ita-srv-tittle {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 50px 0px 0px 50px;
}

.ita-srv-span {
  width: 30%;
  color: #1e2e59;
  font-weight: bold;
  font-size: 2.8rem;
  text-align: left;

  margin-bottom: 10px;
  padding-left: 30px;
}

.tcircles {
  width: 30%;
}

.ita-services {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 50px 0px;
}

.ita-services-columns {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 30% 40% 30%);
}

.srv-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.srv-img h2 {
  color: #54c86e;
  font-size: 1.6rem;

  padding: 20px 0px;
  text-align: center;
  font-weight: lighter;
}

.ita-services img {
  width: 150px;
  height: 150px;

  /* background-color: #f2f2f2; */

  /* border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #f2f2f2; */
  object-fit: contain;
}

.ita-services-sec1 {
  width: 80%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ita-services-sec2 {
  width: 80%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ita-srv-info-container {
  grid-column: 1 / span 3;

  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ita-srv-info {
  min-width: 80%;
  max-width: 80%;
  height: auto;
  min-height: 300px;
  max-height: 350px;

  background-color: #1e2e59;
  border-radius: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;

  padding: 50px 50px 50px 50px;

  margin: 0px 0px;

  color: #ffffff;
}

.ita-srv-info-tittle {
  width: 100%;
  height: 30%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #54c86e;
  font-weight: bold;

  padding-bottom: 20px;

  text-align: center;
}

.ita-srv-info-text {
  width: 80%;
  height: 70%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  color: #ffffff;

  overflow: auto;
  padding-top: 0px;

  padding-right: 50px;
  padding-left: 50px;
}

.ita-srv-info-text p {
  padding-bottom: 50px;
}

.ita-services-sec3 {
  width: 80%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

ul {
  list-style-type: disc;
  padding-bottom: 50px;
}

li {
  padding-bottom: 10px;
}

/*  */
.coffe {
  font-size: 5rem;
}

.circles {
  width: 80%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.small-green-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #54c86e;
}

/*  */
footer {
  border: black solid 1px;
  background: #0a0a2c;
  color: white;
  padding: 1.1rem 0;
}
footer h3 {
  font-size: 0.9rem;
  font-weight: lighter;
}

/*  */
.first {
  grid-column: 1;
  grid-row: 1;

  padding-left: 50px;
}

.second {
  grid-column: 2;
  grid-row: 1;
}

.third {
  grid-column: 3;
  grid-row: 1;

  padding-right: 50px;
}

.fourth {
  grid-column: 1 / span 2;
  grid-row: 5;

  padding-top: 20px;
  padding-right: 10%;
}

.fifth {
  grid-column: 2 / span 2;
  grid-row: 5;
  padding-top: 20px;
  padding-left: 50px;
}

/* triagles */
.triangles-sec1 {
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  grid-row: 2;
  grid-column: 1 / span 3;
}

.triangles-sec2 {
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  grid-row: 4;
  grid-column: 1 / span 3;
}

.hidden-triangle {
  width: 0;
  height: 0;
  border-right: 0px;
  border-top: 0px;
  border-left: 0px;
  border-bottom: 0px;
}

.tleft-triangle {
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-top: 30px solid transparent;
  border-left: 30px solid #1e2e59;
  border-bottom: 30px solid #1e2e59;
}

.ttop-triangle {
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-top: 0px solid transparent;
  border-left: 30px solid transparent;
  border-bottom: 60px solid #1e2e59;
}

.tright-triangle {
  width: 0;
  height: 0;
  border-right: 30px solid #1e2e59;
  border-top: 30px solid transparent;
  border-left: 30px solid transparent;
  border-bottom: 30px solid #1e2e59;
}

.bleft-triangle {
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-top: 60px solid #1e2e59;
  border-left: 30px solid transparent;
  border-bottom: 0px solid transparent;
}

.bbottom-triangle {
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-top: 60px solid #1e2e59;
  border-left: 30px solid transparent;
  border-bottom: 0px solid transparent;
}

.bright-triangle {
  width: 0;
  height: 0;
  border-right: 30px solid #1e2e59;
  border-top: 30px solid #1e2e59;
  border-left: 30px solid transparent;
  border-bottom: 30px solid transparent;
}

.first-triangle {
  grid-column: 1;
  grid-row: 2;

  padding-left: 80px;
}

.second-triangle {
  grid-column: 2;
  grid-row: 2;
}

.third-triangle {
  grid-column: 3;
  grid-row: 2;

  padding-right: 80px;
}

.fourth-triangle {
  grid-column: 1;
  grid-row: 4;

  padding-left: 80px;
}

.fifth-triangle {
  grid-column: 2;
  grid-row: 4;
}

.sixth-triangle {
  grid-column: 3;
  grid-row: 4;

  padding-right: 80px;
}

/* mobile design */
@media screen and (max-width: 480px) {
  .services-sect-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    margin-top: 100px;
  }

  .ita-services-columns {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(2, auto);
  }

  .ita-srv-info-container {
    grid-column: 1 / span 2;

    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    grid-row: 2;
  }

  .ita-srv-tittle {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .ita-srv-span {
    width: 100%;
    color: #1e2e59;
    font-weight: bold;
    font-size: 2.8rem;
    text-align: center;
    margin-bottom: 10px;
    padding-left: 0px;
  }

  .tcircles {
    width: 100%;
    padding: 0px 50px;
  }

  .ita-srv-info-tittle {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #54c86e;
    font-weight: bold;

    margin-bottom: 0px;
  }

  .ita-srv-info {
    width: 90%;
    height: auto;
    background-color: #1e2e59;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    margin: 50px 0px;
    color: #ffffff;
    min-width: 90%;
    max-width: 90%;
    padding: 30px 50px 50px 50px;
  }

  .ita-srv-info-text {
    width: 100%;
    height: auto;

    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #ffffff;

    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .srv-img h2 {
    color: #54c86e;
    font-size: 1.2rem;
    padding: 20px 0px;
    text-align: center;
    font-weight: lighter;
  }

  .ita-services-sec1 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .ita-services-sec2 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .ita-services-sec3 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
  }

  .first {
    grid-column: 1;
    grid-row: 1;
    padding-left: 0px;
    padding-top: 20px;
  }

  .second {
    grid-column: 2;
    grid-row: 1;
    padding-top: 20px;
  }

  .third {
    grid-column: 1;
    grid-row: 3;
    padding-right: 0px;
    padding-top: 30px;
  }

  .fourth {
    grid-column: 2;
    grid-row: 3;
    padding-left: 0px;
    padding-top: 30px;
    padding-right: 0;
  }

  .fifth {
    grid-column: 1 / span 2;
    grid-row: 4;
    padding-top: 30px;
    padding-left: 0px;
  }
}
